import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export function getSeuClube(clubeId, uId) {
  const searchType =
    window.location.host === 'localhost:3000' ||
    window.location.host === '192.168.11.173:3000' ||
    window.location.host.indexOf('seuclube.org') >= 0 ||
    window.location.host.indexOf('nossoclube.org') >= 0
      ? 'clubeId'
      : 'userDomains';

  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/seuClube/getSeuClube?${searchType}=${clubeId}`, {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          // console.log("RESULTADO SEU CLUBE ==>", result.data.result);
          resolve(result.data.result);
          sessionStorage.setItem('seuClubeInfos', JSON.stringify(result.data.result));
        })
        .catch((error) => {
          // console.log('ERRO SEU CLUBE ==>', error);
          return reject(error.response.data);
        });
    });
  });
}
