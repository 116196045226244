import axios from 'axios';

import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from './authToken';

export function apiOrder(itemId, receiver, requestLat, requestLon, sender, type) {
  return new Promise(function (resolve, reject) {
    authToken()
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_V2') + '/order',
            {
              itemId,
              receiver,
              requestDate: Date.now(),
              requestLat,
              requestLon,
              sender,
              status: 'requested',
              type,
            },
            {
              headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
            }
          )
          .then((result) => {
            //console.log('RESULTADO API ORDER ====>', result);
            resolve(result);
          })
          .catch((error) => {
            //console.log('ERRO API ORDER ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKE:", error);
        reject(error);
      });
  });
}
