import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, CircularProgress } from '@mui/material';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1';
import { colorText, trackEventMatomoElementId, getLastUserInfo } from 'js/library/utils/helpers';

import './productStyles.css';
import { theme } from 'index';

export default function Products({ productList }) {
  const { t } = useTranslation();

  const [totalPoints, setTotalPoints] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userInfo = getLastUserInfo();
    getWallet_v1(userInfo.uId)
      .then((wallet) => {
        setTotalPoints(wallet.totalPoints);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {productList === null ? (
        <div align="center">
          <CircularProgress
            style={{
              padding: '150px 20px',
              boxSizing: 'content-box',
              color: theme.palette.primary.main,
            }}
          />
        </div>
      ) : (
        <div>
          {productList.length > 0 ? (
            <div>
              {loading ? (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress
                    size={20}
                    style={{ color: colorText(theme.palette.primary.main) }}
                  />
                </div>
              ) : (
                <Typography
                  variant="subtitle2"
                  style={{
                    fontWeight: 'bold',
                    color: '#6E3296',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <i>
                    Você tem: {totalPoints} {totalPoints === 1 ? 'ponto' : 'pontos'}{' '}
                  </i>
                </Typography>
              )}
              <div className="container-product">
                {productList.map((row, x) => {
                  const id = row.id;
                  row = row[row.type];

                  return (
                    <section key={id} className="card-product">
                      <div>
                        <Link
                          onClick={() =>
                            trackEventMatomoElementId(
                              'Estabelecimento',
                              'tocar',
                              'listaDeProdutos',
                              id
                            )
                          }
                          to={{
                            pathname: '/oferta/',
                            search: '?id=' + id,
                            state: row,
                          }}
                          style={{
                            display: 'inherit',
                            textDecoration: 'none',
                          }}
                        >
                          <img
                            alt="promo"
                            className="img-radius"
                            width="100%"
                            src={
                              cfac22('STORAGE_URL') +
                              encodeURIComponent(row.thumbnail) +
                              '?alt=media'
                            }
                          />
                        </Link>
                      </div>
                      <div className="item-card">
                        <Typography color="primary" variant="h6">
                          {row.establishmentName}
                        </Typography>
                        <Typography variant="h6" style={{ color: 'black', fontWeight: 'bolder' }}>
                          <i>{row.title}</i>
                        </Typography>
                        <Typography
                          className="limit-text"
                          variant="caption"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {row.description}
                        </Typography>
                        <Typography
                          style={{
                            color: 'black',
                            fontWeight: 'bold',
                            marginTop: '20px',
                          }}
                          className="limit-text"
                          variant="caption"
                        >
                          {row.voucherPaymentTemplates ? 'Resgatar com voucher' : row.cost + 'pts'}
                        </Typography>
                      </div>
                    </section>
                  );
                })}
              </div>
            </div>
          ) : (
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '20px',
                color: '#8B8B8B',
              }}
              className="limit-text"
              variant="caption"
            >
              {t('general.establishment_without_products')}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
