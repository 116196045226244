import axios from 'axios';

import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from './authToken';

export function apiConsumeVoucher(
  uId,
  // clientName,
  triiboId,
  storeId
) {
  return new Promise(function (resolve, reject) {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_V2') + '/vouchers/consumeVoucher',
            {
              triiboId,
              storeId,
            },
            {
              headers: {
                authorization: `Bearer ${token}`,
                'Full-Url': window.location.href,
                // clientName: clientName,
              },
            }
          )
          .then((result) => {
            //console.log('RESULTADO API CONSUME VOUCHER ====>', result);
            resolve(result);
          })
          .catch((error) => {
            //console.log('ERRO API CONSUME VOUCHER ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKE:", error);
        reject(error);
      });
  });
}
