import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export default function deleteFavorite(uId, id, type) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .delete(`${cfac22('API_HOST_V2')}/users/favorites`, {
            headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
            data: {
              payload: { id: id, type: type },
            },
          })
          .then((result) => {
            // console.log("RESULT_DELETE_FAVORITES: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            console.log('ERROR_DELETE_FAVORITES: ', error);
            return reject(error);
          });
      })
      .catch((error) => {
        console.log('ERROR_TOKE:', error);
        reject(error);
      });
  });
}
