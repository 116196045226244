import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export function createUserPartner(uid, clubId, partnerList) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/userPartner',
          {
            uid,
            clubId: clubId,
            partnerList: partnerList,
          },
          {
            headers: { authorization: 'Bearer ' + token },
            'Full-Url': window.location.href,
          }
        )
        .then(async (result) => {
          //logando usuário no firebase
          resolve({ userInfo: result.data.response });
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
          return reject(error.response);
        });
    });
  });
}
