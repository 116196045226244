import 'firebase/messaging';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging/sw';

const config = {
  apiKey: cfac22('FIREBASE_APIKEY'),
  authDomain: cfac22('FIREBASE_AUTHDOMAIN'),
  projectId: cfac22('FIREBASE_PROJECTID'),
  storageBucket: cfac22('FIREBASE_STORAGEBUCKET'),
  messagingSenderId: cfac22('FIREBASE_MESSAGINGSENDERID'),
  appId: cfac22('FIREBASE_APP_ID'),
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseStorage = getStorage(firebaseImpl);
export const firebaseAuth = getAuth(firebaseImpl);

let messagingTest = null;

// TRATA ERRO DE INCOMPATIBILIDADE DO SAFARI
try {
  messagingTest = getMessaging(firebaseImpl);
} catch (error) {
  console.log(error);
}

export const messaging = messagingTest;
