import React, { useEffect, useState } from 'react';

import { Button, CircularProgress, InputAdornment, TextField, Select } from '@mui/material';
import { Buttons, DataContainer, Title } from '../styles';
import InputMask from 'react-input-mask';
import SellIcon from '@mui/icons-material/Sell';
import * as S from './styles';
import axios from 'axios';
import {
  getLastUserInfo,
  maskCEP,
  trackEventMatomo,
  trackEventMatomoVisit,
} from 'js/library/utils/helpers';
import { toast } from 'react-toastify';
import ApplydiscountCoupon from 'js/library/utils/API/payment/apiApplydiscountCoupon';
import createLead from 'js/library/utils/API/payment/apiCreateLead';
import phoneMaskArray from '../../LoginForms/phoneMaskArray.json';
import { useTranslation } from 'react-i18next';

export default function PersonalData({
  setData,
  data,
  setCurrentComponent,
  productData,
  setLeadInfos,
}) {
  const { t } = useTranslation();

  const [cupom, setCupom] = useState(localStorage.getItem('cupom') ?? '');
  const [loadingCupom, setLoadingCupom] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [cellPhone, setCellPhone] = useState('');
  const [country, setCountry] = useState('+55,(99) 99999-9999');
  const [countryNumber, setCountryNumber] = useState('+55');
  const [mask, setMask] = useState('(99) 99999-9999');
  const userInfo = getLastUserInfo();

  const maskVerify =
    (countryNumber + mask).replace(/\D/g, '').length ===
    (countryNumber + cellPhone).replace(/\D/g, '').length;

  useEffect(() => {
    trackEventMatomoVisit('Pagamento: Dados pessoais');
    document.title = 'Pagamento: Dados pessoais';
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem('cupom');
    };

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  const handleZipCodeChange = async (event) => {
    const zipCode = maskCEP(event.target.value);
    setData({
      ...data,
      addressData: {
        ...data.addressData,
        postcode: zipCode,
      },
    });

    if (zipCode.length === 9) {
      const { data: dataCep } = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);
      setData({
        ...data,
        addressData: {
          ...data.addressData,
          address_1: dataCep.logradouro,
          city: dataCep.localidade,
          state: dataCep.uf,
          postcode: zipCode,
          neighborhood: dataCep.bairro,
        },
      });
    }
  };

  const handleChangeAddressData = (e, name) => {
    setData({
      ...data,
      addressData: {
        ...data.addressData,
        [name]: e.target.value,
      },
    });
  };

  const handleChangeValueData = (e, name) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const applyCoupon = async () => {
    if (data.firstName === '') {
      toast.error('O Nome é obrigatório!');
      return;
    }

    if (data.lastName === '') {
      toast.error('O Sobrenome é obrigatório!');
      return;
    }

    if (data.email === '') {
      toast.error('O Email é obrigatório!');
      return;
    }

    if (data.confirm_email === '') {
      toast.error('Confirme o Email!');
      return;
    }

    if (data.confirm_email !== data.email) {
      toast.error('E-mail de confirmação diferente do original!');
      return;
    }

    if (cellPhone.length !== 0) {
      if (!maskVerify) {
        toast.warn('Celular inválido.');
        return;
      }
    }

    setLoadingCupom(true);

    let uId = null;

    if (!userInfo.triiboId.includes('@anonymous')) {
      uId = userInfo.uId;
    }

    let obj = {
      uId,
      fone: data.fone,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      productType: productData.productType,
      productId: productData.id,
      quantity: productData.quantity,
    };

    if (productData.productType === 'subscription') {
      obj.subscriptionInfo = productData.subscriptionData;
    }

    if (productData.productType === 'physical') {
      obj = {
        ...obj,
        address_1: data.addressData.address_1,
        address_2: data.addressData.address_2,
        city: data.addressData.city,
        state: data.addressData.state,
        postcode: data.addressData.postcode,
        country: data.addressData.country,
        neighborhood: data.addressData.neighborhood,
        number: data.addressData.number,
        fone: data.fone,
      };
    }

    let leadData = {};

    if (!leadId) {
      try {
        const leadDataRes = await createLead(obj);
        setLeadId(leadDataRes.response.lead.id);
        setLeadInfos(leadDataRes.response.lead);

        leadData = leadDataRes;
      } catch (error) {
        toast.error('Ocorreu um erro. Tente novamente mais tarde.');
      }
    }

    if (leadData.success || leadId) {
      try {
        const leadIdToUse = leadId || leadData.response.lead.id;
        const result = await ApplydiscountCoupon(uId, leadIdToUse, cupom);
        const discount_total = result.response.discount_total;
        const total = result.response.total;

        localStorage.setItem('discount_total', discount_total);
        localStorage.setItem('total', total);
        localStorage.setItem('cupom', cupom);

        const discountValue = Number(discount_total).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        const totalValue = Number(total).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        toast.info(
          `Cupom adicionado. Desconto: ${discountValue}. Valor total do produto: ${totalValue}`
        );
        setLoadingCupom(false);
        trackEventMatomo('Pagamento: dados pessoais', 'click', 'button', 'Aplicar cupom');
      } catch (err) {
        toast.warning(err.error);
        setLoadingCupom(false);
      }
    } else {
      setLoadingCupom(false);
    }
  };

  const validationMessages = () => {
    if (data.firstName === '') {
      toast.error('O Nome é obrigatório!');
      return;
    }

    if (data.lastName === '') {
      toast.error('O Sobrenome é obrigatório!');
      return;
    }

    if (data.email === '') {
      toast.error('O Email é obrigatório!');
      return;
    }

    if (data.confirm_email === '') {
      toast.error('Confirme o Email!');
      return;
    }

    if (data.confirm_email !== data.email) {
      toast.error('E-mail de confirmação diferente do original!');
      return;
    }

    if (cellPhone.length !== 0) {
      if (!maskVerify) {
        toast.warn('Celular inválido.');
        return;
      }
    }

    if (cupom === '') {
      localStorage.removeItem('discount_total');
      localStorage.removeItem('total');
    }

    setCurrentComponent('cardData');
    trackEventMatomo('Pagamento: dados pessoais', 'click', 'button', 'Avançar');
  };

  const handleChangeCellphone = (event, name) => {
    if (name === 'cellPhone') {
      setCellPhone(event.target.value);

      setData({
        ...data,
        fone: '+' + (countryNumber + event.target.value).replace(/\D/g, ''),
      });
    } else if (name === 'country') {
      setCellPhone('');
      let countrySlice = event.target.value;
      countrySlice = countrySlice.split(',');
      setCountry(event.target.value);
      setCountryNumber(countrySlice[0]);
      setMask(countrySlice[1]);

      setData({
        ...data,
        fone: '+' + (countrySlice[0] + '').replace(/\D/g, ''),
      });

      document.getElementById('cellPhone').focus();
    }
  };

  return (
    <DataContainer>
      <Title>{t('checkout.personalData')}</Title>
      <form>
        {productData.productType === 'physical' && (
          <>
            <TextField
              margin="normal"
              fullWidth
              required
              label={t('labels.zip_code')}
              value={data.addressData.postcode}
              onChange={handleZipCodeChange}
            />
            <TextField
              margin="normal"
              fullWidth
              required
              label={t('labels.address')}
              value={data.addressData.address_1}
              onChange={(e) => handleChangeAddressData(e, 'address_1')}
            />
            <TextField
              margin="normal"
              fullWidth
              required
              label={t('labels.number')}
              value={data.addressData.number}
              onChange={(e) => handleChangeAddressData(e, 'number')}
            />
            <TextField
              margin="normal"
              fullWidth
              required
              label={t('labels.city')}
              value={data.addressData.city}
              onChange={(e) => handleChangeAddressData(e, 'city')}
            />
            <TextField
              margin="normal"
              fullWidth
              required
              label={t('labels.state')}
              value={data.addressData.state}
              onChange={(e) => handleChangeAddressData(e, 'state')}
            />
            <TextField
              margin="normal"
              fullWidth
              required
              label={t('labels.neighborhood')}
              value={data.addressData.neighborhood}
              onChange={(e) => handleChangeAddressData(e, 'neighborhood')}
            />
            <TextField
              margin="normal"
              fullWidth
              label={t('labels.complement')}
              value={data.addressData.address_2}
              onChange={(e) => handleChangeAddressData(e, 'address_2')}
            />
          </>
        )}

        <TextField
          margin="normal"
          fullWidth
          required
          label={t('general.name')}
          value={data.firstName}
          onChange={(e) => handleChangeValueData(e, 'firstName')}
        />

        <TextField
          margin="normal"
          fullWidth
          required
          label={t('labels.lastname')}
          value={data.lastName}
          onChange={(e) => handleChangeValueData(e, 'lastName')}
        />

        {userInfo.triiboId.includes('@anonymous') ? (
          <>
            <Select
              style={{ width: '23%', marginRight: '4px' }}
              native
              value={country}
              onChange={(e) => handleChangeCellphone(e, 'country')}
            >
              <option value={''} disabled>
                {t('labels.country')}
              </option>
              {phoneMaskArray
                .sort((a, b) => {
                  const nameA = a.initials.toUpperCase(); // ignore upper and lowercase
                  const nameB = b.initials.toUpperCase(); // ignore upper and lowercase
                  if (nameB === 'BR') {
                    return 9999999999;
                  }
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((country) => (
                  <option key={country.id} value={[country.ddd, country.mask]}>
                    {country.initials} {country.ddd}
                  </option>
                ))}
            </Select>
            <InputMask
              disabled={country === ''}
              mask={mask}
              type="tel"
              value={cellPhone}
              onChange={(e) => handleChangeCellphone(e, 'cellPhone')}
            >
              {() => (
                <TextField
                  style={{ width: '76%' }}
                  value={cellPhone}
                  disabled={country === ''}
                  placeholder={country === '' ? 'Celular' : mask}
                  id="cellPhone"
                />
              )}
            </InputMask>
          </>
        ) : (
          <></>
        )}

        <TextField
          margin="normal"
          fullWidth
          required
          // type='email'
          label={t('labels.email')}
          value={data.email}
          onChange={(e) => handleChangeValueData(e, 'email')}
        />
        <TextField
          error={data.email !== data.confirm_email}
          margin="normal"
          fullWidth
          required
          // type='email'
          label={t('labels.confirm_email')}
          value={data.confirm_email}
          onChange={(e) => handleChangeValueData(e, 'confirm_email')}
          helperText={data.email !== data.confirm_email && 'Email inválido.'}
        />
        <S.Coupon>
          <TextField
            margin="normal"
            type="text"
            label={t('checkout.checkout_discount_code')}
            value={cupom}
            onChange={(e) => setCupom(e.target.value)}
            disabled={loadingCupom}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SellIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                applyCoupon();
              }
            }}
          />
          <Button
            variant="contained"
            size="large"
            onClick={applyCoupon}
            disabled={loadingCupom || cupom === ''}
          >
            {loadingCupom ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <CircularProgress size={30} />
              </div>
            ) : (
              t('checkout.apply')
            )}
          </Button>
        </S.Coupon>
        <Buttons>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={loadingCupom}
            onClick={() => {
              setCurrentComponent('purchaseDetails');
              trackEventMatomo('Pagamento: dados pessoais', 'click', 'button', 'Voltar');
            }}
          >
            {t('general.back')}
          </Button>
          <Button
            onClick={validationMessages}
            variant="contained"
            color="primary"
            size="large"
            disabled={loadingCupom}
          >
            {t('checkout.next')}
          </Button>
        </Buttons>
      </form>
    </DataContainer>
  );
}

