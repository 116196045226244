import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'styles/css/index.css';
import App from 'js/components/App';
import { store, persistor } from 'js/core/configureStore';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import i18n from './js/i18n';
import { GlobalStyle } from 'js/containers/Drawer/UploadUserPhotos/uploadUserPhoto';
import SignUpProvider from 'js/context/SignUpContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppProvider } from 'js/context/AppProvider';

const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

export const theme = createTheme({
  palette: {
    primary: {
      main: infoSeuClube && infoSeuClube.colorPrimary ? infoSeuClube.colorPrimary : '#000',
    },
    secondary: {
      main: infoSeuClube && infoSeuClube.colorSecondary ? infoSeuClube.colorSecondary : '#FFF',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    fontSize: 12,
    fontWeight: '700',
    color: {
      primary: infoSeuClube && infoSeuClube.colorPrimary ? infoSeuClube.colorPrimary : '#000',
      secondary:
        infoSeuClube && infoSeuClube.colorSecondary ? infoSeuClube.colorSecondary : '#f0f0f0',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('seu-clube'));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AppProvider>
        <SignUpProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <I18nextProvider i18n={i18n}>
                <ToastContainer autoClose={4000} />
                <App />
                <GlobalStyle />
              </I18nextProvider>
            </ThemeProvider>
          </BrowserRouter>
        </SignUpProvider>
      </AppProvider>
    </PersistGate>
  </Provider>
);
