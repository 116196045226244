import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default function getPostById(uId, postId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .get(
            `${cfac22('API_HOST_V2')}/feedPosts/${postId}`,

            { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
          )
          .then((result) => {
            // console.log("RESULT_GetFeedPosts: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log("ERROR_GetFeedPosts: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKE:", error);
        reject(error);
      });
  });
}
