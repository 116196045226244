import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export function preRegisters(clubeId, partnerId, partnerName, userToken, userCellphone) {
  return new Promise(async (resolve, reject) => {
    const userRegister = {
      clubeId: clubeId,
      partnerInfo: {
        partnerId: partnerId,
        partnerName: partnerName,
      },
      token: userToken,
    };

    if (userCellphone) {
      userRegister.partnerInfo.contactList = {
        type: 'cellPhone',
        value: userCellphone,
      };
      userRegister.cellphone = userCellphone;
    }

    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/preRegisters`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Full-Url': window.location.href,
        },
        data: {
          bunch: [userRegister],
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}
