import { Box } from '@mui/material';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getLastUserInfo, getUrlVariables, isDynamicHost } from 'js/library/utils/helpers';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AllModules } from 'synoro-package/packages';

export default function LandingPage() {
  const urlVars = getUrlVariables();
  const props = useParams();
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  const dynamicHost = isDynamicHost();

  const id = props.id;
  const userInfo = getLastUserInfo();

  return (
    <Box
      sx={{
        marginTop: !dynamicHost && { xs: '45px', sm: '62px' },
        marginBottom: !dynamicHost && { xs: '30px', sm: 0 },
        minHeight: dynamicHost ? '100vh' : '86vh',
      }}
    >
      <AllModules
        id={urlVars.id}
        publicName={id || urlVars.publicName || window.location.host}
        partnerId={infoSeuClube.partnerId}
        userInfo={userInfo}
        ambient={cfac22('ambient')}
      />
    </Box>
  );
}
