import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Paper } from '@mui/material';
import placeholderItem from 'styles/assets/placeholder/Itens_Placeholder.gif';
import { downloadImage } from 'js/library/services/StorageManager.js';
import { trackEventMatomoElementId } from 'js/library/utils/helpers';

export const BalanceItem = (voucher) => {
  const [thumbnail, setThumbnail] = React.useState(placeholderItem);
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  voucher = voucher.voucher;

  function downloadImageOnLoad() {
    if (voucher.thumbnail !== undefined) {
      downloadImage('voucher', voucher.thumbnail)
        .then((downloaded) => {
          setThumbnail(downloaded);
        })
        .catch((error) => {
          return error;
        });
    }
  }

  const descricao =
    voucher.description === undefined
      ? ''
      : voucher.description.length < 70
      ? voucher.description
      : voucher.description.substring(0, 70) + '...';

  return (
    <Link
      className="swiper-slide"
      onClick={() => trackEventMatomoElementId('Carteira', 'click', 'cupom', voucher?.key)}
      to={`/carteira/detalhes/${voucher.key}`}
      state={voucher}
      style={{
        height: '100%',
        textDecoration: 'none',
      }}
    >
      <Paper sx={{ padding: '10px', height: '100%' }}>
        <img
          style={{ width: '100%', height: 'auto' }}
          src={thumbnail}
          alt="carteira"
          onLoad={() => thumbnail === placeholderItem && downloadImageOnLoad()}
        />
        <div style={{ overflow: 'hidden' }}>
          <Typography
            style={{ fontWeight: 'bold', color: infoSeuClube.colorPrimary }}
            variant="subtitle1"
          >
            {voucher.title}
          </Typography>

          <Typography style={{ color: 'black' }} variant="body1">
            {voucher.establishmentName}
          </Typography>

          <Typography style={{ color: 'black' }} variant="caption">
            {descricao}
          </Typography>
        </div>
      </Paper>
    </Link>
  );
};
export default BalanceItem;

