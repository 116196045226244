import axios from 'axios';
import { decrypt, encrypt } from '../../utils/helpers';
import cfac22 from 'js/library/utils/cfac22/cfac22';

function getSecurityCode() {
  return new Promise(async (resolve, reject) => {
    let auth = window.btoa(cfac22('username') + ':' + cfac22('password'));

    const config = {
      method: 'get',
      url: cfac22('API_HOST_V2') + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
        'Full-Url': window.location.href,
      },
    };

    axios(config)
      .then(function (sc) {
        let decry = JSON.parse(decrypt(sc.data.securityCode, cfac22('SECURITYCODE_KEY')));
        let result = { code: decry.code * 3 };
        result = encrypt(JSON.stringify(result), cfac22('SECURITYCODE_KEY'));
        resolve(result);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function authTokenWithSC(uid) {
  return new Promise(async (resolve, reject) => {
    let sc = await getSecurityCode();
    let auth = window.btoa(cfac22('username') + ':' + cfac22('password'));

    const config = {
      method: 'post',
      url: cfac22('API_HOST_V2') + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
        securityCode: sc,
        uid: uid,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("response", response);
        resolve(response.data.token);
      })
      .catch(function (error) {
        console.log('error', error);
        reject(error);
      });
  });
}
