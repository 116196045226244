import { createContext, useContext, useState } from 'react';

// Cria o contexto unificado
const AppContext = createContext({});

export function AppProvider({ children }) {
  // Estados para Home.js
  const [cards, setCards] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [pureOrgs, setPureOrgs] = useState([]);

  return (
    <AppContext.Provider
      value={{
        cards: {
          cards,
          setCards,
          orgs,
          setOrgs,
          pureOrgs,
          setPureOrgs,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => {
  return useContext(AppContext);
};
