import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';

export function getCardsByOrgs(uId, orgID) {
  //OBTER INFORMAÇÕES DO USUÁRIO

  return new Promise((resolve, reject) => {
    getChannelToken_v1(uId)
      .then((result) => {
        //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);
        axios
          .post(
            cfac22('API_HOST_V1') + 'getCardsByOrgs',
            {
              triiboHeader: {
                apiToken: cfac22('apiToken'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                uId: uId,
                channelTokenId: result,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              useUId: true,
              orgID: orgID,
              platform: 'web',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then((result) => {
            //console.log('RESULTADO GET CARDs BY ORG ===>', result);

            resolve(result.data);
          })
          .catch((error) => {
            //console.log('ERROR GET CARDs BY ORG ---->', error.response);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ---->', error);
        reject(error);
      });
  });
}
