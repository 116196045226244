import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';

export function getWallet_v1(userId) {
  //OBTER INFORMAÇÕES DO USUÁRIO

  return new Promise((resolve, reject) => {
    getChannelToken_v1(userId).then((result) => {
      //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);

      axios
        .post(
          cfac22('API_HOST_V1') + 'getWallet_v1',
          {
            triiboHeader: {
              apiToken: cfac22('apiToken'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: userId,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Full-Url': window.location.href,
            },
          }
        )
        .then((result) => {
          //console.log('RESULTADO GET WALLET ===>', result);
          const wallet = {
            coupons: result.data.success.wallet,
            totalPoints: result.data.success.totalPoints,
          };

          resolve(wallet);
        })
        .catch((error) => {
          //console.log('ERROR GET WALLET ===>', error);
          reject(error);
        });
    });
  });
}
