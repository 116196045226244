import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';
import { decrypt } from '../helpers';

export function validateUserPartner(accessToken, partnerId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/userPartner/validateUserPartner',
          {
            token: accessToken,
            partnerId: partnerId,
          },
          {
            headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
          }
        )
        .then(async (result) => {
          resolve(JSON.parse(decrypt(result.data.response, cfac22('CRYPTO_KEY'))));
        })
        .catch((error) => {
          console.log(error);
          reject(error.response);
        });
    });
  });
}
