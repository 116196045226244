import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default function submitLeadPayment(uId, leadId, holder, brand, cardNumber, expirationDate) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            // eslint-disable-next-line no-undef
            `${cfac22('API_HOST_V2')}/checkout/payment/card`,
            {
              paymentInfo: {
                leadId,
                cardInfo: {
                  holder,
                  brand,
                  cardNumber,
                  expirationDate, // ex: 07/2024
                },
              },
            },
            {
              headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
            }
          )
          .then(async (result) => {
            console.log('RESULT_CreateLead: ', result);
            resolve(result.data);
          })
          .catch((error) => {
            // console.log('ERROR_SUBMIT_LEAD: ', error);
            return reject(error.response.data);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKEN:", error);
        reject(error);
      });
  });
}
