import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Grid,
  Input,
  IconButton,
  CircularProgress,
  Tooltip,
  Divider,
} from '@mui/material';
import { Send } from '@mui/icons-material';

import InfiniteScroll from 'react-infinite-scroll-component';

import GoBack from 'js/containers/GoBack.js';
import LoadingLists from 'js/containers/Loading/LoadingLists';
import { getLastUserInfo, getUrlVariables, trackEventMatomoVisit } from 'js/library/utils/helpers';
import { getStorePlaces_v2 } from 'js/library/utils/API/getStorePlaces_v2';

import Establishment from 'js/containers/StorePlaces/Establishment';
import Store from 'js/containers/StorePlaces/Store';
import Product from 'js/containers/StorePlaces/Product';
import getPostById from 'js/library/utils/API/apiGetPostById';
import { getCardByID } from 'js/library/utils/API/getCardByID';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { theme } from 'index';

function setStoredList(
  itemId,
  itemInfo,
  objectType,
  latitude,
  longitude,
  from,
  hasMore,
  arrayComplete
) {
  if (!window.storedList) window.storedList = {};

  //gravando p/ salvar o carregamento no retorno do usuário
  window.storedList.itemId = itemId;
  window.storedList.itemInfo = itemInfo;
  window.storedList.objectType = objectType;
  window.storedList.latitude = latitude;
  window.storedList.longitude = longitude;
  window.storedList.from = from;
  window.storedList.hasMore = hasMore;
  window.storedList.arrayComplete = arrayComplete;
  //fim gravação
}

function setObjectType(action) {
  switch (action) {
    case 'promotion_list':
      return 'promotion';
    case 'product_list':
      return 'product';
    case 'establishment_list':
      return 'establishment';
    default:
      return 'promotion';
  }
}

export function StorePlacesLists() {
  const userInfo = getLastUserInfo();
  const getVars = getUrlVariables();
  const location = useLocation();

  const id = getVars.id;

  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  //verificando se a lista salva no storage é valida
  const validStored =
    window.storedList !== null &&
    window.storedList !== undefined &&
    window.storedList.itemId === id &&
    window.storedList.itemInfo;

  const [itemInfo, setItemInfo] = useState(null);

  const [objectType, setStateObjectType] = useState([]);

  const [arrayComplete, setArrayComplete] = useState(null);
  const [arrayResearch, setArrayResearch] = useState(null);

  const [research, setResearch] = useState('');
  const [researchList, setResearchList] = useState(false);

  //informações de chamada
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [from, setFrom] = useState(0);
  const size = 12;
  const [hasMore, setHasMore] = useState(true);
  const [tootipIsOpen, setTootipIsOpen] = useState(false);

  const { t } = useTranslation();

  const arrayList = researchList ? arrayResearch : arrayComplete;

  useEffect(() => {
    async function getPromos() {
      //verificando se a lista já foi carregada e está salva no storage
      if (!validStored && itemInfo === null) {
        const getCoords = async () => {
          try {
            const pos = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            return {
              long: pos.coords.longitude,
              lat: pos.coords.latitude,
            };
          } catch (error) {
            return {
              long: null,
              lat: null,
            };
          }
        };

        const coords = await getCoords();
        const latitude = coords.lat;
        const longitude = coords.long;

        let objectTypeState = [];
        let infoBody = null;

        if (location.state) {
          //tratando info que chegou via state e tratando campos diferentes
          infoBody = location.state;
          infoBody.query =
            infoBody.query || (infoBody.appFilter && infoBody.appFilter.query) || null;

          if (infoBody.cardId) infoBody.image = infoBody.mobileDetails;

          objectTypeState = setObjectType(infoBody.action.type ?? infoBody.action);
        } else {
          //baixando dados caso info não tenha chegado via state
          let infoBodies = [];

          infoBodies = await Promise.allSettled([
            getPostById(userInfo.uId, id),
            getCardByID(userInfo.uId, id),
          ]);

          //verificando qual api conseguiu retornar resultados
          if (infoBodies[0].status === 'fulfilled') {
            //post ---- adequando campos diferentes
            infoBody = infoBodies[0].value.result;
            infoBody.query = infoBody.appFilter.query;
            objectTypeState = setObjectType(infoBody.action.type);
          } else {
            //card ---- adequando campos diferentes
            infoBody = infoBodies[1].value.card;
            infoBody.image = infoBody.mobileDetails;
            objectTypeState = setObjectType(infoBody.action);
          }
        }

        //setando para futuras consultas
        setStateObjectType(objectTypeState);
        setItemInfo(infoBody);
        setLatitude(latitude);
        setLongitude(longitude);

        getStorePlaces_v2(
          userInfo.uId,
          objectTypeState,
          latitude,
          longitude,
          '',
          null,
          infoBody.query,
          from,
          size
        ).then((dataReceived) => {
          setStoredList(
            id,
            infoBody,
            objectTypeState,
            null,
            null,
            size + 1,
            dataReceived.list.length >= size,
            dataReceived.list,
            null
          );

          setArrayComplete(dataReceived.list);
          setFrom(size);
          setHasMore(dataReceived.list.length >= size);
        });
      } else if (itemInfo === null) {
        setItemInfo(window.storedList.itemInfo);
        setStateObjectType(window.storedList.objectType);
        setArrayComplete(window.storedList.arrayComplete);
        setFrom(window.storedList.from);
        setHasMore(window.storedList.hasMore);
      }
    }

    getPromos();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //iniciar a página do topo
    window.scrollTo(0, 0);

    document.title = 'Lista de promocao';

    trackEventMatomoVisit('ListaDePromocoes');
  }, []);

  const handleChange = (name) => (event) => {
    setResearch(event.target.value);

    if (event.target.value.length === 0 && researchList) {
      setResearchList(false);
      setArrayResearch(null);
    }
  };

  const fetchMoreData = () => {
    getStorePlaces_v2(
      userInfo.uId,
      objectType,
      latitude,
      longitude,
      '',
      null,
      itemInfo.query,
      from,
      size
    ).then((dataReceived) => {
      const arrayList = arrayComplete.concat(dataReceived.list ?? []);
      window.storedList.arrayComplete = arrayList;
      window.storedList.from = from + size;
      window.storedList.hasMore = dataReceived.list.length >= 12;

      setArrayComplete(arrayList);
      setFrom(from + size);
      setHasMore(dataReceived.list.length >= 12);
    });
  };

  const researchData = (e) => {
    e.preventDefault();
    setResearchList(true);
    // setResearch('');

    getStorePlaces_v2(
      userInfo.uId,
      objectType,
      userInfo.latitude,
      userInfo.longitude,
      research,
      null,
      itemInfo.query,
      null,
      null
    ).then((dataReceived) => {
      setArrayResearch(dataReceived.list);
    });
  };

  return (
    <div className="paper-container" style={{ minHeight: '100vh' }}>
      {itemInfo === null ? (
        <div style={{ textAlign: 'center' }} className="loading-content">
          <CircularProgress style={{ color: theme.palette.primary.main }} />
        </div>
      ) : (
        <div>
          <div className="header-content-offer">
            <div style={{ display: 'flex' }}>
              <GoBack />
              <Typography
                variant="h6"
                style={{
                  fontWeight: 'bolder',
                  width: '85%',
                  textAlign: 'center',
                }}
              >
                {itemInfo.title}
              </Typography>
            </div>

            <Divider color="secondary" style={{ margin: '10px 0px' }} />

            <img
              src={cfac22('STORAGE_URL') + encodeURIComponent(itemInfo.image) + '?alt=media'}
              alt="thubmnail"
              width="100%"
              style={{ borderRadius: '15px' }}
            />

            <Typography
              variant="subtitle1"
              style={{
                whiteSpace: 'break-spaces',
                margin: '0.5rem',
              }}
            >
              {itemInfo.description}
            </Typography>

            <Typography
              color="secondary"
              variant="subtitle1"
              style={{ whiteSpace: 'break-spaces' }}
            >
              {itemInfo.message}
            </Typography>
            <Tooltip arrow open={tootipIsOpen} title={t('errors.mandatory_three')}>
              <div className="search-bar">
                <Grid container spacing={0}>
                  <Grid item xs={false} sm={2} md={3}></Grid>

                  <Grid item xs={12} sm={8} md={6}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        if (research.length > 2) {
                          setTootipIsOpen(false);
                          researchData(e);
                        } else {
                          e.preventDefault();
                          setTootipIsOpen(true);
                          setTimeout(() => {
                            setTootipIsOpen(false);
                          }, 2000);
                        }
                      }}
                    >
                      <Input
                        placeholder={t('general.search_here')}
                        id="search"
                        fullWidth
                        value={research}
                        onChange={handleChange('research')}
                        endAdornment={
                          <IconButton disabled={research.length === 0} type="submit">
                            {arrayList === null ? (
                              <CircularProgress
                                size={25}
                                style={{ color: infoSeuClube.colorPrimary }}
                              />
                            ) : (
                              <Send />
                            )}
                          </IconButton>
                        }
                      />
                    </form>
                  </Grid>

                  <Grid item xs={false} sm={2} md={3}></Grid>
                </Grid>
              </div>
            </Tooltip>
          </div>

          {arrayList === null ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress
                style={{
                  padding: '150px 20px',
                  boxSizing: 'content-box',
                  color: infoSeuClube.colorPrimary,
                }}
              />
            </div>
          ) : arrayList.length > 0 ? (
            <div>
              <InfiniteScroll
                dataLength={arrayList.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={arrayList.length > 11 && <LoadingLists />}
                style={{ marginTop: '30px' }}
              >
                <Grid container spacing={0}>
                  {arrayList.map((row, i) => {
                    if (!row) return null;
                    if (row.type === 'establishment') {
                      return <Establishment key={`${row.id}-${i}`} data={row} />;
                    } else if (row.type === 'promotion' || row.type === 'businessPartner') {
                      return <Store key={`${row.id}-${i}`} data={row} />;
                    } else {
                      return <Product key={`${row.id}-${i}`} data={row} />;
                    }
                  })}
                </Grid>
              </InfiniteScroll>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '40px',
              }}
            >
              <Typography variant="subtitle1">{t('errors.no_data_found')}</Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default function WrappedComponent(props) {
  const location = useLocation();

  return <StorePlacesLists location={location} {...props} />;
}
