import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Rating, CircularProgress, Grid } from '@mui/material';
import { Star } from '@mui/icons-material';

import { ModalRatings } from './ModalRatings';
import { colorText, trackEventMatomo, checkAnonymous } from 'js/library/utils/helpers';
import getRatings from 'js/library/utils/API/getRatings';
import { toast } from 'react-toastify';

import './stylesRating.css';
import { theme } from 'index';

export default function Ratings(item) {
  const { t } = useTranslation();

  const userInfo = item.userInfo;
  const establishmentInfo = item.establishmentInfo;

  const isAnonymous = checkAnonymous(userInfo);

  //const [hover, setHover] = useState(-1);
  const [ratingNumber, setRatingNumber] = useState(0);
  const [userMessage, setUserMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [establishmentRatings, setEstablishmentRatings] = useState(null);

  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  useEffect(() => {
    getRatings(userInfo.uId, establishmentInfo.id)
      .then((result) => {
        const userAvaliation = result.establishmentInfo.ratings.filter(
          (item) => item.uid === userInfo.uId
        );
        if (userAvaliation.length > 0) {
          setRatingNumber(userAvaliation[0].rating);
          setUserMessage(userAvaliation[0].userComment);
        }
        setEstablishmentRatings(result.establishmentInfo);
      })
      .catch(() => {
        setEstablishmentRatings({ ratings: [], ratingsScore: {} });
      });
  }, [userInfo, establishmentInfo]);

  const handleRating = () => {
    if (ratingNumber === 0) {
      toast.warning(t('errors.avaliation_field_not_filled'));
    } else {
      trackEventMatomo('Estabelecimento', 'tocar', 'botao', 'Avaliar');

      if (isAnonymous) {
        item.checkAnonymous();
        return;
      }

      if (infoSeuClube.chargeType && !userInfo.userProfile) {
        item.setOpenStateSubscription(true);
        return;
      }

      setOpen(true);
    }
  };

  const toggleModalRatings = () => {
    setOpen(false);
  };

  return (
    <div className="container-rating">
      {establishmentRatings ? (
        <>
          <div className="content-result-rating">
            {/* <section className="content-linear-progress">
          <div className="item-linear-progress">
            <span>5</span>
            <BorderLinearProgress variant="determinate" value={80} />
          </div>
          <div className="item-linear-progress">
            <span>4</span>
            <BorderLinearProgress variant="determinate" value={75} />
          </div>
          <div className="item-linear-progress">
            <span>3</span>
            <BorderLinearProgress variant="determinate" value={20} />
          </div>
          <div className="item-linear-progress">
            <span>2</span>
            <BorderLinearProgress variant="determinate" value={10} />
          </div>
          <div className="item-linear-progress">
            <span>1</span>
            <BorderLinearProgress variant="determinate" value={10} />
          </div>
        </section> */}

            <section className="content-number-starts">
              <div className="item-number-starts">
                <span>
                  {establishmentRatings.ratingsScore.avg
                    ? establishmentRatings.ratingsScore.avg
                    : 0}
                </span>
                <Rating
                  name="read-only"
                  precision={0.5}
                  readOnly
                  style={{ fontSize: '20px' }}
                  value={
                    establishmentRatings.ratingsScore.avg
                      ? establishmentRatings.ratingsScore.avg
                      : 0
                  }
                />
              </div>
            </section>
          </div>

          <div className="area-rating">
            <Rating
              name="hover-feedback"
              style={{ fontSize: '2.5rem', color: 'gold' }}
              value={ratingNumber}
              precision={0.5}
              onChange={(_, newValue) => {
                setRatingNumber(newValue);
              }}
              /* onChangeActive={(_, newHover) => {
                setHover(newHover);
              }} */
              onTouchStart={(_, newValue) => {
                setRatingNumber(newValue);
              }}
            />
            <button
              style={{
                background: theme.palette.primary.main,
                color: colorText(theme.palette.primary.main),
              }}
              type="button"
              className="button-rate"
              onClick={handleRating}
            >
              {t('general.rate')}
            </button>
          </div>

          <div>
            {establishmentRatings.ratings.map((item, index) => {
              return (
                <Grid container key={index}>
                  <Grid item xs={4}>
                    <Star style={{ color: 'gold', fontSize: '3rem' }} />
                    <span className="number-star">{item.rating}</span>
                  </Grid>
                  <Grid item xs={8}>
                    <i className="name-user">{item.aliasName ? item.aliasName : 'Triiber'}</i>
                    <i className="text-message">{item.userComment}</i>
                  </Grid>
                  <Divider style={{ float: 'right', width: '95%' }} />
                </Grid>
              );
            })}
          </div>

          <ModalRatings
            establishmentInfo={establishmentInfo}
            userInfo={userInfo}
            ratingNumber={ratingNumber}
            toggleModalRatings={toggleModalRatings}
            open={open}
            userMessage={userMessage}
          />
        </>
      ) : (
        <div align="center" className="loading-content">
          <CircularProgress style={{ color: colorText(theme.palette.primary.main) }} />
        </div>
      )}
    </div>
  );
}

