import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default function deleteMessage(uId, messageId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .delete(`${cfac22('API_HOST_V2')}/message/${messageId}`, {
            headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
          })
          .then((result) => {
            // console.log("RESULT_DELETE_MESSAGE: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            console.log('ERROR_DELETE_MESSAGE: ', error);
            return reject(error);
          });
      })
      .catch((error) => {
        console.log('ERROR_TOKEN:', error);
        reject(error);
      });
  });
}
