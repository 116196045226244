import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export function linkAccount(uidUser, linkedAccount, triiboId) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        axios
          .post(
            `${cfac22('API_HOST_V2')}/login/linkAccount`,
            {
              linkedAccount,
              triiboId,
              uidUser,
            },
            { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
          )
          .then((result) => {
            // console.log("RESULT LINK ACCOUNT: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            console.log('ERROR_LINK_FAVORITE: ', error);
            return reject(error);
          });
      })
      .catch((error) => {
        console.log('ERROR_TOKEN:', error);
        reject(error);
      });
  });
}
