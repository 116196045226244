import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export default function establishmentRatings(uId, establishmentId, rating, userComment, aliasName) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            `${cfac22('API_HOST_V2')}/establishments/ratings`,
            {
              payload: {
                aliasName,
                rating,
                establishmentId,
                userComment,
              },
            },
            { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
          )
          .then((result) => {
            // console.log("RESULT_RATINGS: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            console.log('ERROR_RATINGS: ', error);
            return reject(error);
          });
      })
      .catch((error) => {
        console.log('ERROR_TOKE:', error);
        reject(error);
      });
  });
}
