import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Box,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import QRCode from 'qrcode.react';
import {
  colorText,
  convertToDateDefault,
  formatDate,
  checkAnonymous,
  trackEventMatomo,
} from 'js/library/utils/helpers';
import placeholderItem from 'styles/assets/placeholder/placeholder.gif';
import consumedVoucher from 'styles/assets/Wallet/consumedVoucher.png';
import consumedVoucher_ES from 'styles/assets/Wallet/consumedVoucher_ES.png';
import { downloadImage } from 'js/library/services/StorageManager.js';
import { getLastUserInfo } from 'js/library/utils/helpers';
import getVoucherById from 'js/library/utils/API/getVoucherById';
import { SignUpContext } from 'js/context/SignUpContext';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default function Voucher() {
  const isAnonymous = checkAnonymous(getLastUserInfo());
  const verifyLanguage = localStorage.getItem('i18nextLng');

  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();

  const voucherId = params.id;

  const [voucher, setVoucher] = useState(location.state);

  const vouchers = location.state.vouchers;
  const [thumbnail, setThumbnail] = useState(placeholderItem);

  const [securityCode, setSecurityCode] = useState('');
  const [hasCopiedSC, setHasCopiedSC] = useState(false);
  const [loadingVoucher, setLoadingVoucher] = useState(true);

  const [colorPrimary, setColorPrimary] = useState('');
  const { infosSeuClube } = useContext(SignUpContext);

  const consumed = voucher.state === 'finished' || voucher.status === 'consumido';

  useEffect(() => {
    if (infosSeuClube) {
      setColorPrimary(infosSeuClube.colorPrimary);
    }
  }, [infosSeuClube]);

  const copyVoucher = (e, type) => {
    e.preventDefault();

    if (type === 'securityCode') {
      navigator.clipboard.writeText(securityCode);
      setHasCopiedSC(true);
    }

    setTimeout(() => {
      setHasCopiedSC(false);
    }, 1500);
  };

  const pageRef = useRef();

  const generatePDF = async () => {
    const element = pageRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('cupom.pdf');
  };

  useEffect(() => {
    document.title = 'Detalhes da carteira';

    getVoucherById(voucherId, []).then((response) => {
      const newVoucher = { ...voucher, ...response.result.voucher };
      setVoucher(newVoucher);
      setSecurityCode(response.result.voucher.voucherCodeValidate ?? false);
      setLoadingVoucher(false);
    });
    // eslint-disable-next-line
  }, []);

  const downloadThumb = () => {
    if (voucher.thumbnail !== undefined) {
      downloadImage('voucher', voucher.thumbnail).then((img) => {
        setThumbnail(img);
      });
    }
  };

  if (isAnonymous) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className="paper-container">
        {loadingVoucher ? (
          <div style={{ textAlign: 'center', alignContent: 'center', height: '82vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div>
              <Button
                style={{ cursor: 'default', backgroundColor: '#000000' }}
                disabled
                fullWidth={true}
                variant="contained"
              >
                <span
                  style={{
                    color: 'white',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  }}
                >
                  <Typography variant="h6">
                    <span className="white-font">
                      {consumed ? 'Cupons inativos' : 'Cupons Ativos'}
                    </span>
                  </Typography>
                </span>
              </Button>

              <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                <img
                  width="90%"
                  className="img-radius"
                  src={thumbnail}
                  onLoad={() => downloadThumb()}
                  alt="carteira"
                />

                <div style={{ padding: '15px' }} ref={pageRef}>
                  <Typography variant="subtitle2">{voucher.kind}</Typography>

                  <Typography
                    variant="h6"
                    color="primary"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {voucher.detailsTitle || voucher.title}
                  </Typography>

                  <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle2">
                    {voucher.detailsDescription || voucher.description}
                  </Typography>

                  {voucher.score ? (
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontWeight: 'bold',
                        paddingTop: '25px',
                      }}
                    >
                      {t('wallet.total_user_coupons', {
                        coupons: voucher.score,
                      })}
                    </Typography>
                  ) : null}

                  {voucher.type === 'store' || voucher.type === 'promotion' ? (
                    <div style={{ marginTop: '30px' }}>
                      {consumed ? (
                        <img
                          src={verifyLanguage === 'pt-BR' ? consumedVoucher : consumedVoucher_ES}
                          width="70%"
                          alt="cupom consumido"
                        />
                      ) : (
                        (voucher.showQrCode === undefined || voucher.showQrCode === true) && (
                          <QRCode
                            id="QRCode"
                            value={voucher.key}
                            size={140}
                            bgColor={'#ffffff'}
                            fgColor={'#000000'}
                            level={'M'}
                          />
                        )
                      )}

                      {(voucher.showVoucherCodes === undefined ||
                        voucher.showVoucherCodes === true) && (
                        <Typography variant="body1">{voucher.key}</Typography>
                      )}

                      {/* mensagem antiga do else t('wallet.for_redeem_your_coupon') */}
                      {voucher.consumerHelpText && (
                        <Typography variant="caption">
                          {consumed
                            ? t('wallet.coupon_was_consumed_on') +
                              convertToDateDefault(voucher.consumedDate)
                            : voucher.consumerHelpText}
                        </Typography>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {(voucher.showQrCode === true ||
              voucher.showQrCode === undefined ||
              voucher.showVoucherCodes === true ||
              voucher.showVoucherCodes === undefined) && (
              <Box display="flex" justifyContent="center" mt="20px">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generatePDF}
                  size="small"
                  style={{ marginBottom: '20px' }}
                >
                  Salvar cupom como PDF
                </Button>
              </Box>
            )}

            {voucher.showVoucherCodes && securityCode && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="caption" color="primary">
                  Código de verificação:
                </Typography>
                <Box
                  sx={{
                    borderRadius: '30px',
                    width: '300px',
                    padding: '10px',
                    backgroundColor: colorPrimary,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <Typography
                    variant="span"
                    sx={{ color: colorText(colorPrimary), textAlign: 'center' }}
                  >
                    {securityCode}
                  </Typography>
                  <Tooltip open={hasCopiedSC} title="Copiado com sucesso!">
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        copyVoucher(e, 'securityCode');
                      }}
                      sx={{
                        backgroundColor: '#333',
                        borderRadius: '30px',
                        ':hover': {
                          backgroundColor: '#333',
                        },
                      }}
                    >
                      Copiar
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            )}

            {voucher.consumptionType !== 'externalConsumption' ? (
              <div
                style={{
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  paddingTop: '10px',
                }}
              >
                <List>
                  {vouchers
                    ? Object.keys(vouchers).map(function (item, i) {
                        return (
                          <ListItem divider key={item}>
                            <ListItemText
                              primary={
                                <div>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {Object.keys(voucher.vouchers)[i]}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {formatDate(voucher.vouchers[item].sentDate)}
                                  </Typography>
                                </div>
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton aria-label="Comments">
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {voucher.vouchers[item].value}
                                </Typography>
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      }, this)
                    : ''}
                </List>
              </div>
            ) : null}

            <div style={{ paddingBottom: '20px', textAlign: 'center' }}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 'bold',
                }}
                component={(props) => (
                  <Link
                    color="primary"
                    onClick={() =>
                      trackEventMatomo('Voucher', 'tocar', 'botao', 'Voltar para a carteira')
                    }
                    to={{ pathname: '/carteira' }}
                    {...props}
                    style={{ textDecoration: 'none', fontWeight: '700' }}
                  />
                )}
              >
                {t('wallet.back_to_wallet')}
              </Typography>
            </div>
          </>
        )}
      </div>
    );
  }
}

