import { useEffect, useState } from 'react';
import { getCardsByOrgs } from 'js/library/utils/API/getCardsByOrgs';
import { getLastUserInfo } from 'js/library/utils/helpers';
import { useAppContext } from './AppProvider';

export default function useGetCardsHomeClub() {
  const {
    cards: { cards, setCards, orgs, setOrgs, pureOrgs, setPureOrgs },
  } = useAppContext();

  useEffect(() => {
    const userInfo = getLastUserInfo();
    if (!userInfo) return;

    const seuClubeInfos = sessionStorage.getItem('seuClubeInfos');
    if (!seuClubeInfos) return;

    const orgBySeuClube = JSON.parse(seuClubeInfos)?.orgs?.[0];
    if (!orgBySeuClube) return;

    if (cards === null)
      getCardsByOrgs(userInfo.uId, orgBySeuClube).then((result) => {
        if (result.success) {
          setPureOrgs(result.logos);
          let cards = {};

          let orgs = result.logos.filter((org) => {
            let hasCards = 0;

            result.cards
              .filter((card) => card.orgs.indexOf(org.orgID) >= 0)
              .map((item) => {
                hasCards++;

                return cards[org.orgID] === undefined
                  ? (cards[org.orgID] = [item])
                  : cards[org.orgID].push(item);
              });

            return hasCards > 0;
          });

          setCards(cards);
          setOrgs(orgs);
        }
      });
  }, []);

  return { cards, orgs, pureOrgs, setOrgs, setCards };
}
