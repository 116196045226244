import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, CircularProgress, Button, Tabs, Tab, Box } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';

import placeholderItem from 'styles/assets/placeholder/placeholder.gif';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { toast } from 'react-toastify';

import {
  colorText,
  getLastUserInfo,
  getUrlVariables,
  trackEventMatomo,
  trackEventMatomoVisit,
  checkAnonymous as checkAnonymousFunc,
} from 'js/library/utils/helpers';
import { downloadImage } from 'js/library/services/StorageManager.js';

import { getStorePlacesItem_v2 } from 'js/library/utils/API/getStorePlacesItem_v2.js';
import { getStorePlaces_v2 } from 'js/library/utils/API/getStorePlaces_v2';
import { getUserInfoBasic_v1 } from 'js/library/utils/API/getUserInfo_v1';
import addFavorite from 'js/library/utils/API/addFavorite';
import deleteFavorite from 'js/library/utils/API/deleteFavorite';

import GoBack from 'js/containers/GoBack.js';
import CuponsComponent from './CuponsComponent';
import Products from './Products';
import Ratings from './Rating';
import PlaceAbout from './PlaceAbout';

import './placesStyles.js';
import { Container } from './placesStyles.js';
import { theme } from 'index';

export default function Places(props) {
  const { t } = useTranslation();
  const userInfo = getLastUserInfo();
  const getVars = getUrlVariables();
  const id = getVars?.id;

  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  const isAnonymous = checkAnonymousFunc(userInfo);

  const [establishmentInfo, setEstablishmentInfo] = useState(
    window.location.state === undefined ? null : window.location.state
  );

  const [productList, setProductList] = useState(null);
  const [promotionList, setPromotionList] = useState(null);
  const [listaFotosDownloaded, setListaFotosDownloaded] = useState([]);

  const [isFavorite, setIsFavorite] = useState(false);

  const [loading, setLoading] = useState(false);

  //iniciando com 2 apenas para evitar alerta de erro do mui, o valor será setado abaixo
  const [tabIndex, setTabIndex] = useState('2');

  useEffect(() => {
    const checkFavoriteUser = (establishmentInfo) => {
      const establishmentInfoId = establishmentInfo?.id;

      setLoading(true);

      getUserInfoBasic_v1(['getUserInfo'], { uId: userInfo.uId })
        .then((resultUser) => {
          setLoading(false);
          const favorites = resultUser?.favorites;

          if (!favorites) {
            setIsFavorite(false);
            return;
          }

          // VERIFICA SE O USUÁRIO FAVORITOU O ESTABELECIMENTO
          favorites.forEach(({ id }) => {
            if (id === establishmentInfoId) {
              setIsFavorite(true);
            }
          });
        })
        .catch((error) => {
          toast.error(t('errors.try_later'));
          setLoading(true);
        });
    };

    async function getEstab() {
      document.title = 'Estabelecimento';
      trackEventMatomoVisit('Estabelecimento');

      //iniciar a página no topo
      window.scrollTo(0, 0);

      ///baixando dados caso não venha via props
      const dataEstab = !establishmentInfo
        ? await getStorePlacesItem_v2(null, id, userInfo.uId)
        : establishmentInfo;

      //atualizando state caso info seja baixada
      if (dataEstab.placesItem !== establishmentInfo) setEstablishmentInfo(dataEstab.placesItem);

      //tratativa de erro caso estab não exista
      if (!dataEstab || !dataEstab.placesItem) setEstablishmentInfo(false);

      //checando se estab foi favoritado pelo usuário
      if (!isAnonymous) checkFavoriteUser(dataEstab.placesItem);

      //buscando ofertas do estabelecimento
      getStorePlaces_v2(userInfo.uId, ['promotion', 'product'], 0, 0, '', id, '', 0, 200)
        .then((dataReceived) => {
          let promotionList = [];
          let productList = [];

          dataReceived.list.map((item) => {
            if (item.type === 'product') {
              return productList.push(item);
            } else {
              return promotionList.push(item);
            }
          });

          setTabIndex(productList.length > 0 ? '0' : promotionList.length > 0 ? '1' : '2');
          setPromotionList(promotionList);
          setProductList(productList);
        })
        .catch(() => {
          setPromotionList([]);
          setProductList([]);
        });

      if (dataEstab?.placesItem?.listaFotos) {
        dataEstab.placesItem.listaFotos.map((fotos, i) => {
          return downloadImage('estabelecimento', fotos)
            .then((result) => {
              let listaFotosDownloadedAux = listaFotosDownloaded;
              listaFotosDownloaded[i] = result;
              setListaFotosDownloaded(listaFotosDownloadedAux);
            })
            .catch((error) => {
              return error;
            });
        });
      }
    }
    getEstab();

    // eslint-disable-next-line
  }, []);

  const handleFavorite = async () => {
    if (isAnonymous) {
      checkAnonymous();
      return;
    }

    const { uId } = userInfo;
    const idEstablishmentInfo = establishmentInfo.id;
    const type = 'establishment';

    setIsFavorite(!isFavorite);

    try {
      if (isFavorite) {
        await deleteFavorite(uId, idEstablishmentInfo, type);
        toast.success(t('general.removed_successfully'));
      } else {
        await addFavorite(uId, idEstablishmentInfo, type);
        toast.success(t('general.added_successfully'));
      }
    } catch (error) {
      toast.error(t('errors.try_later'));
    }
  };

  const checkAnonymous = () => {
    if (isAnonymous) {
      props.setOpenState(true);
    }
  };

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: false,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
  };

  const handleChangeTabs = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Container className="paper-container">
      {establishmentInfo === null ? (
        <div style={{ textAlign: 'center' }} className="loading-content">
          <CircularProgress style={{ color: theme.palette.primary.main }} />
        </div>
      ) : establishmentInfo === false ? (
        <div align="center">
          <Typography variant="h6">{t('general.did_not_find_were_looking_for')}</Typography>
          <Button
            onClick={() => {
              window.history.back();
              trackEventMatomo(`estabelecimento/?id=${id}`, 'tocar', 'Voltar');
            }}
            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
          >
            {t('general.back')}
          </Button>
        </div>
      ) : (
        <div>
          {/* HEADER  */}
          <div className="place-header">
            <GoBack />
            <span color="primary">{establishmentInfo.nome}</span>
            {loading ? (
              <CircularProgress
                style={{ color: colorText(theme.palette.primary.main) }}
                size={20}
              />
            ) : (
              <>
                {isFavorite ? (
                  <Favorite
                    className="iconHeart"
                    color="primary"
                    onClick={() => {
                      handleFavorite();
                      trackEventMatomo('Estabelecimento', 'tocar', 'botao', 'Desfavoritar');
                    }}
                  />
                ) : (
                  <FavoriteBorder
                    color="primary"
                    className="iconHeart"
                    onClick={() => {
                      handleFavorite();
                      trackEventMatomo('Estabelecimento', 'tocar', 'botao', 'Favoritar');
                    }}
                  />
                )}
              </>
            )}
          </div>
          {/* SLIDE IMAGES */}
          <div className="slider-home">
            <Slide {...properties}>
              {establishmentInfo.listaFotos ? (
                establishmentInfo.listaFotos.map((_, index) => {
                  const thumb =
                    listaFotosDownloaded[index] === undefined
                      ? placeholderItem
                      : listaFotosDownloaded[index];
                  return (
                    <div key={index} className="each-slide">
                      <img width="100%" src={thumb} alt={index} />
                    </div>
                  );
                })
              ) : (
                <img width="100%" src={placeholderItem} alt="placeholder" />
              )}
            </Slide>
          </div>

          {promotionList && productList && (
            <TabContext value={tabIndex}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleChangeTabs}
                  sx={{
                    padding: '0 12px',
                    background: '#fff',
                    '& button:hover': { background: '#dadada' },
                    '& button.Mui-selected': {
                      color: infoSeuClube.colorPrimary,
                    },
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: infoSeuClube.colorPrimary,
                    },
                  }}
                  centered
                  variant={window.innerWidth < 600 ? 'fullWidth' : 'standard'}
                >
                  {productList && productList.length > 0 && (
                    <Tab className="tabs-title" label={t('general.store')} value={'0'} />
                  )}
                  {promotionList && promotionList.length > 0 && (
                    <Tab className="tabs-title" label={t('general.coupons')} value={'1'} />
                  )}
                  <Tab
                    className="tabs-title"
                    onClick={() =>
                      trackEventMatomo(`estabelecimento/?id=${id}`, 'tocar', 'tabs', 'Sobre')
                    }
                    label={t('general.about')}
                    value={'2'}
                  />
                  <Tab
                    className="tabs-title"
                    onClick={() =>
                      trackEventMatomo(`estabelecimento/?id=${id}`, 'tocar', 'tabs', 'Avaliação')
                    }
                    label={t('general.rating')}
                    value={'3'}
                  />
                </Tabs>
              </Box>

              {productList && productList.length > 0 && (
                <TabPanel value={'0'}>
                  <Products userInfo={userInfo} productList={productList} />
                </TabPanel>
              )}
              {promotionList && promotionList.length > 0 && (
                <TabPanel value={'1'}>
                  <CuponsComponent promotionList={promotionList} />
                </TabPanel>
              )}
              <TabPanel value={'2'}>
                <PlaceAbout
                  setOpenStateSubscription={props.setOpenStateSubscription}
                  checkAnonymous={checkAnonymous}
                  userInfo={userInfo}
                  establishmentInfo={establishmentInfo}
                />
              </TabPanel>
              <TabPanel value={'3'}>
                <Ratings
                  setOpenStateSubscription={props.setOpenStateSubscription}
                  checkAnonymous={checkAnonymous}
                  establishmentInfo={establishmentInfo}
                  userInfo={userInfo}
                />
              </TabPanel>
            </TabContext>
          )}
        </div>
      )}
    </Container>
  );
}
