import axios from 'axios';
import { validateRegistrationDate } from '../../utils/helpers';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default async function authToken(uId) {
  return new Promise(async (resolve, reject) => {
    //PEGA O TOKEN DO LOCAL_STORAGE
    const token = JSON.parse(localStorage.getItem('triiboTokenSeuClube'));

    let auth = window.btoa(cfac22('username') + ':' + cfac22('password'));

    const config = {
      method: 'post',
      url: cfac22('API_HOST_V2') + '/auth',
      headers: {
        Authorization: 'Basic ' + auth,
        uid: uId ?? null,
        'Full-Url': window.location.href,
      },
    };

    if (uId) {
      config.headers = {
        ...config.headers,
        uid: uId,
      };
    }

    //VERIFICA SE O TOKEN AINDA É VALIDO
    const valideDate = validateRegistrationDate(token?.date, 15);
    if (token === null || token === undefined || !valideDate || uId !== token.uId) {
      axios(config)
        .then(function (response) {
          localStorage.setItem(
            'triiboTokenSeuClube',
            JSON.stringify({
              token: response.data.token,
              date: Date.now(),
              uId,
            })
          );
          resolve(response.data.token);
        })
        .catch(function (error) {
          reject(error);
        });
    } else {
      resolve(token.token);
    }
  });
}
