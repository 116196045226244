import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export function login(cellphone, linkedAccount) {
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/login',
          {
            cellphone: cellphone,
            linkedAccount: linkedAccount ?? null,
            partnerId: seuClubePersist.partnerId,
          },
          {
            headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
          }
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
