import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default function checkProduct(uId, productId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .get(
            // eslint-disable-next-line no-undef
            `${cfac22('API_HOST_V2')}/checkout/product/${productId}`,
            {
              headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href },
            }
          )
          .then(async (result) => {
            // console.log("RESULT_CheckProduct: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log("ERROR_CheckProduct: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKEN:", error);
        reject(error);
      });
  });
}
