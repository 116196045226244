import React, { useContext, useEffect, useState } from 'react';

import phoneMaskArray from 'js/components/LoginForms/phoneMaskArray.json';
import * as S from '../styles';
import { colorText, randomizeNumbersString } from 'js/library/utils/helpers';
import { authCode_v1 } from 'js/library/utils/API/authCode_v1';
import { SignUpContext } from 'js/context/SignUpContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { CheckUserPartnerCellphone } from 'js/library/utils/API/checkUserPartnerCellphone';

export default function InformCellPhone({ setCurrentModel }) {
  const [inputValue, setInputValue] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [currentCountry, setCurrentCountry] = useState('');
  // const [fullMask, setFullMask] = useState('+55(99)99999-9999');
  const [cellphoneInput, setCellphoneInput] = useState('');
  const [loading, setLoading] = useState(false);

  const { setAuthSmsInfos, setFormData, setCellPhone, formData, validateInfos } =
    useContext(SignUpContext);
  const { t } = useTranslation();

  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    setInputValue('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    // setFullMask(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  const sendSms = () => {
    const sendType = {
      sms: true,
      email: true,
      whatsapp: false,
    };

    authCode_v1(null, cellphoneInput, sendType, null)
      .then((res) => {
        if (res.error) {
          return toast.error(t('errors.try_later'));
        }
        setAuthSmsInfos(res);
        setCellPhone(cellphoneInput);
        if (!validateInfos.user) {
          setFormData({
            ...formData,
            contactList: formData['contactList']
              ? [
                  ...formData['contactList'],
                  {
                    type: 'cellPhone',
                    value: cellphoneInput,
                  },
                ]
              : [
                  {
                    type: 'cellPhone',
                    value: cellphoneInput,
                  },
                ],
          });
        }
        setCurrentModel('sms');
        setLoading(false);
      })
      .catch((err) => {
        toast.error('Erro ao enviar SMS.');
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userPartners = await CheckUserPartnerCellphone(partnerId, cellphoneInput);

      if (userPartners.response.message === 'Usuário já vinculado ao partner') {
        setLoading(false);
        toast.error(t('errors.cellphone_already_exists'));
        return;
      }

      sendSms();
    } catch (error) {
      sendSms();
    }
  };

  useEffect(() => {
    setCellphoneInput(currentDDD + inputValue.replace(/\D/g, ''));
  }, [inputValue, currentDDD]);

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.ContainerInput>
        <S.BoxCellPhone>
          <S.Adornment>
            <select
              style={{
                fontFamily: 'Source Sans Pro, sans-serif',
                flex: '1',
                height: '100%',
                border: 'none',
                outline: 'none',
                fontSize: '17px',
                color: '#383838d6',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
              value={currentCountry}
              onChange={(e) => handleCountryChange(e)}
            >
              {phoneMaskArray.map((country, index) => (
                <option
                  style={{
                    fontFamily: 'Source Sans Pro, sans-serif',
                  }}
                  key={country.id}
                  value={[country.ddd, country.mask, country.fullMask]}
                >
                  {country.initials} {country.ddd}
                </option>
              ))}
            </select>
          </S.Adornment>

          <S.Input
            mask={inputMask}
            value={inputValue}
            placeholder={randomizeNumbersString(inputMask)}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </S.BoxCellPhone>
      </S.ContainerInput>

      <S.Button
        type="submit"
        bgColor={seuClubePersist.colorPrimary}
        style={{ color: colorText(seuClubePersist.colorPrimary) }}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress
            size={20}
            style={{ margin: '0 auto', color: colorText(seuClubePersist.colorPrimary) }}
          />
        ) : (
          'Confirmar'
        )}
      </S.Button>
    </S.Form>
  );
}

