import React, { useState, useEffect, useCallback } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { SettingsOutlined } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import {
  getLastUserInfo,
  formatUrl,
  trackEventMatomoVisit,
  trackEventMatomoElementId,
  isFrameUrl,
  checkAnonymous,
} from 'js/library/utils/helpers';
import { downloadImage } from 'js/library/services/StorageManager.js';
import placeholderLogo from 'styles/assets/placeholder/placeholderLogo.png';
import placeholderCard from 'styles/assets/placeholder/placeholderCard.gif';
import { setUserInfo_v1 } from 'js/library/utils/API/setUserInfo_v1';
import { withTranslation } from 'react-i18next';
import { getCardsByOrgs } from 'js/library/utils/API/getCardsByOrgs';
import authToken from 'js/library/utils/API/authToken';
import useGetCardsHomeClub from 'js/context/useGetCardsHomeClub';

const Home = ({ t, setOpenStateSubscription }) => {
  const userInfo = getLastUserInfo();
  let isAnonymous = userInfo !== null;
  isAnonymous = checkAnonymous(userInfo);

  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const orgBySeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos')).orgs[0];

  document.title = 'Home';

  useEffect(() => {
    trackEventMatomoVisit('Home');
  }, []);

  const { cards, orgs, pureOrgs, setCards, setOrgs } = useGetCardsHomeClub();

  const [anchorEl, setAnchorEl] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [listAll, setListAll] = useState([true]);
  const [clickDeslogado, setClickDeslogado] = useState(false);
  const navigate = useNavigate();

  const downloadImageOnLoad = useCallback(
    (data, i, type, orgID) => {
      let list = type === 'orgs' ? [...orgs] : { ...cards };

      if (
        !!list &&
        data !== undefined &&
        data !== null &&
        !list[orgID][i].hasOwnProperty('downloaded')
      ) {
        downloadImage(data.split('/')[0], data.split('/')[1])
          .then((downloaded) => {
            if (orgID) list[orgID][i].downloaded = downloaded;
            else list[i].downloaded = downloaded;

            type === 'orgs' ? setOrgs(list) : setCards(list);
          })
          .catch((error) => {
            return error;
          });
      }
    },
    [orgs, cards]
  );

  const reorderOrgs = useCallback(
    (direction, i) => {
      closeMenu(i);
      let orgsCopy = [...pureOrgs];
      const x = direction === 'up' ? i - 1 : i + 1;

      const aux = orgsCopy[x];
      orgsCopy[x] = orgsCopy[i];
      orgsCopy[i] = aux;

      setOrgs(orgsCopy);

      //atualizando preferência das orgs no firebase
      const userInfoData = {
        uId: userInfo.uId,
        updateDate: new Date().getTime(),
        syncType: 'merge',
        orgs: orgsCopy.map((item) => item.orgID),
      };

      setUserInfo_v1(userInfo.uId, ['setUserInfo'], userInfoData, null);
    },
    // eslint-disable-next-line
    [pureOrgs, userInfo]
  );

  const closeMenu = useCallback(
    (i) => {
      let anchorCopy = [...anchorEl];
      anchorCopy[i] = null;
      setAnchorEl(anchorCopy);
    },
    [anchorEl]
  );

  const handleAuthToken = async (e, url) => {
    e.preventDefault();

    if (isAnonymous) {
      setClickDeslogado(true);
    } else if (infoSeuClube.chargeType && !userInfo.userProfile) setOpenStateSubscription(true);
    else {
      try {
        // Substitui {token_base64} na URL
        if (url.includes('{token_base64}')) {
          const token = await authToken(userInfo.uId);

          url = url.replace('{token_base64}', btoa('Bearer ' + token));
        }

        if (url.includes('{clubDomain}')) {
          url = url.replace('{clubDomain}', window.location.origin);
        }

        if (isFrameUrl(url)) {
          const urlRedirect = new URL(url);
          navigate(urlRedirect.pathname + urlRedirect.search);
        } else {
          window.open(url, '_blank');
        }
      } catch (error) {
        console.error('Erro ao obter o token:', error);
      }
    }
  };

  if (clickDeslogado) return <Navigate to="/validarAcesso" />;

  return (
    <div className="paper-container">
      {orgs === null ? (
        <div className="loading-content" style={{ textAlign: 'center' }}>
          <CircularProgress style={{ color: infoSeuClube.colorPrimary }} />
        </div>
      ) : (
        <div id="scroll-matomo-home">
          {orgs.map((org, i) => {
            const isFirst = i === 0;
            const isLast = i === orgs.length - 1;

            return (
              <div key={org.orgID} style={i + 1 !== orgs.length ? { marginBottom: '40px' } : null}>
                {orgs.length > 1 && (
                  <img
                    className="home-paper-icon"
                    alt="home"
                    onLoad={() => downloadImageOnLoad(org.logoImage, i, 'orgs')}
                    src={org.downloaded === undefined ? placeholderLogo : org.downloaded}
                  />
                )}

                {orgs.length !== 1 && (
                  <div
                    style={{
                      float: 'right',
                      cursor: 'pointer',
                      padding: '15px',
                    }}
                  >
                    <SettingsOutlined
                      aria-expanded={anchorEl[i] ? 'true' : undefined}
                      onClick={(event) => {
                        let anchorCopy = [...anchorEl];
                        anchorCopy[i] = event.currentTarget;
                        setAnchorEl(anchorCopy);
                      }}
                    />
                  </div>
                )}
                <Menu
                  id={'basic-menu' + i}
                  anchorEl={anchorEl[i]}
                  open={Boolean(anchorEl[i])}
                  onClose={() => closeMenu(i)}
                  MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                >
                  {!isFirst && (
                    <MenuItem onClick={() => reorderOrgs('up', i)}>{t('home.move_up')}</MenuItem>
                  )}
                  {!isLast && (
                    <MenuItem onClick={() => reorderOrgs('down', i)}>
                      {t('home.move_down')}
                    </MenuItem>
                  )}
                </Menu>

                {cards[org.orgID].map((card, x) => {
                  const url = formatUrl(card);
                  const cardReplace = card.image.includes('cards/')
                    ? card.image
                    : 'cards/' + card.image;

                  if (x <= 2 || listAll[i]) {
                    if (card.action === 'browser' || card.action === 'webview') {
                      return (
                        <div onClick={(e) => handleAuthToken(e, url)} key={x}>
                          <img
                            style={{ cursor: 'pointer' }}
                            className="home-paper-card"
                            alt="card"
                            onClick={() =>
                              trackEventMatomoElementId('Home', 'tocar', 'card', `${org.orgID}`)
                            }
                            onLoad={() => downloadImageOnLoad(cardReplace, x, 'cards', org.orgID)}
                            src={card.downloaded === undefined ? placeholderCard : card.downloaded}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <Link
                          to={{
                            pathname: url.split('?')[0],
                            search: url.split('?')[1],
                            state: card,
                          }}
                          style={{ textDecoration: 'none' }}
                          key={x}
                        >
                          <img
                            onClick={() =>
                              trackEventMatomoElementId('Home', 'tocar', 'card', `${org.orgID}`)
                            }
                            className="home-paper-card"
                            alt="card"
                            onLoad={() => downloadImageOnLoad(cardReplace, x, 'cards', org.orgID)}
                            src={card.downloaded === undefined ? placeholderCard : card.downloaded}
                          />
                        </Link>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Home);
