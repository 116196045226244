import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export function getUserPartner(partnerId, uid) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/userPartner?partnerId=${partnerId}&uid=${uid}`, {
          headers: { authorization: 'Bearer ' + token, 'Full-Url': window.location.href },
        })
        .then((result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  });
}
