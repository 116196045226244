import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';

export default function postToken(uId, triiboId, notificationToken) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            `${process.env.REACT_APP_API_HOST_V2}/notification/token`,
            {
              triiboId,
              token: notificationToken,
              platform: 'web-app',
            },
            { headers: { authorization: `Bearer ${token}`, 'Full-Url': window.location.href } }
          )
          .then((result) => {
            // console.log("RESULT_POST_TOKEN: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log("ERROR_POST_TOKEN: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_POST_TOKEN:", error);
        reject(error);
      });
  });
}
