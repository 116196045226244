import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export default function getFeedPosts(uId, keywords, orgs, relationships, dateLimit, limit) {
  return new Promise((resolve, reject) => {
    if (uId) {
      authToken(uId).then((token) => {
        axios
          .post(
            `${cfac22('API_HOST_V2')}/feedPosts`,
            {
              keywords,
              orgs,
              relationships,
              dateLimit,
              limit,
            },
            {
              headers: {
                authorization: `Bearer ${token}`,
                contentType: 'application/json',
                'Full-Url': window.location.href,
              },
            }
          )
          .then((result) => {
            resolve(result.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      reject(null);
    }
  });
}
